import React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby-link"
import { withFormik, Form, Field } from "formik"
import * as yup from "yup"
import LinkCog from "../linkCog"

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ApplyForHousingForm = ({ values, errors, touched, isSubmitting }) => (
  <Form
    className="form"
    name="apply_for_housing"
    method="post"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
  >
    <input type="hidden" name="form-name" value="apply_for_housing" />
    <div className="form__group form__group--inline form__group--title">
      <label className="form__label" htmlFor="apply_for_housing_title">
        Title
      </label>
      <Field
        id="apply_for_housing_title"
        component="select"
        name="apply_for_housing_title"
        className="form__select form__select--title"
      >
        <option value="Mr">Mr</option>
        <option value="Mrs">Mrs</option>
        <option value="Miss">Miss</option>
        <option value="Ms">Ms</option>
        <option value="Dr">Dr</option>
      </Field>
      {errors.apply_for_housing_title && touched.apply_for_housing_title ? (
        <p className="form__error">{errors.apply_for_housing_title}</p>
      ) : null}
    </div>
    <div className="form__group form__group--inline form__group--full-name">
      <label className="form__label" htmlFor="apply_for_housing_full_name">
        Full Name
      </label>
      <Field
        type="text"
        className="form__text"
        name="apply_for_housing_full_name"
        id="apply_for_housing_full_name"
      />
      {errors.apply_for_housing_full_name &&
      touched.apply_for_housing_full_name ? (
        <p className="form__error">{errors.apply_for_housing_full_name}</p>
      ) : null}
    </div>

    <div className="form__group form__group--inline form__group--address">
      <label className="form__label" htmlFor="apply_for_housing_street_name">
        First Line of Address
      </label>
      <Field
        type="text"
        className="form__text"
        id="apply_for_housing_street_name"
        name="apply_for_housing_street_name"
      />
      {errors.apply_for_housing_street_name &&
      touched.apply_for_housing_street_name ? (
        <p className="form__error">{errors.apply_for_housing_street_name}</p>
      ) : null}
    </div>
    <div className="form__group form__group--inline form__group--postcode">
      <label className="form__label" htmlFor="apply_for_housing_postcode">
        Postcode
      </label>
      <Field
        type="text"
        className="form__text"
        id="apply_for_housing_postcode"
        name="apply_for_housing_postcode"
      />
      {errors.apply_for_housing_postcode &&
      touched.apply_for_housing_postcode ? (
        <p className="form__error">{errors.apply_for_housing_postcode}</p>
      ) : null}
    </div>
    <div className="form__group form__group--inline form__group--telephone">
      <label className="form__label" htmlFor="apply_for_housing_telephone">
        Daytime Telephone Number
      </label>
      <Field
        type="text"
        className="form__text"
        id="apply_for_housing_telephone"
        name="apply_for_housing_telephone"
      />
      {errors.apply_for_housing_telephone &&
      touched.apply_for_housing_telephone ? (
        <p className="form__error">{errors.apply_for_housing_telephone}</p>
      ) : null}
    </div>
    <div className="form__group form__group--inline form__group--email">
      <label className="form__label" htmlFor="apply_for_housing_email">
        Email Address
      </label>
      <Field
        type="email"
        className="form__text"
        id="apply_for_housing_email"
        name="apply_for_housing_email"
      />
      {errors.apply_for_housing_email && touched.apply_for_housing_email ? (
        <p className="form__error">{errors.apply_for_housing_email}</p>
      ) : null}
    </div>
    <fieldset className="form__group">
      <legend className="form__label">Are you over 55?</legend>
      <div className="form--ha__radio form--ha__radio--inline">
        <label htmlFor="apply_for_housing_over_age_yes">
          <Field
            id="apply_for_housing_over_age_yes"
            type="radio"
            name="apply_for_housing_over_age"
            value="Yes I am over 55"
          />
          Yes
        </label>
      </div>
      <div className="form--ha__radio form--ha__radio--inline">
        <label htmlFor="apply_for_housing_over_age_no">
          <Field
            id="apply_for_housing_over_age_no"
            type="radio"
            name="apply_for_housing_over_age"
            value="No I am not over 55"
          />
          No
        </label>
      </div>
      {errors.apply_for_housing_over_age &&
      touched.apply_for_housing_over_age ? (
        <p className="form__error">{errors.apply_for_housing_over_age}</p>
      ) : null}
    </fieldset>
    <div className="form__group">
      <label
        className="form__label"
        htmlFor="apply_for_housing_process_agreement"
      >
        Data Protection
      </label>
      <p>
        PRHA promises to keep your data safe and secure and in line with the
        General Data Protection Regulation 2018. We will only use your
        information to provide the service you have requested and we will not
        sell your data. You can manage and review your privacy choices at any
        time by contacting You can view{" "}
        <Link to="/privacy-notice">PRHA's privacy notice here</Link>.
      </p>
      <Field
        className="form__checkbox"
        id="apply_for_housing_process_agreement"
        name="apply_for_housing_process_agreement"
        type="checkbox"
        checked={values.apply_for_housing_process_agreement}
      />
      <label
        className="form__label form__label--checkbox"
        htmlFor="apply_for_housing_process_agreement"
      >
        Do you agree for PRHA to process your information?
      </label>
      {errors.apply_for_housing_process_agreement &&
      touched.apply_for_housing_process_agreement ? (
        <p className="form__error">
          {errors.apply_for_housing_process_agreement}
        </p>
      ) : null}
    </div>
    <button
      className="button button--gold"
      type="submit"
      disabled={isSubmitting}
    >
      Send this form <LinkCog />
    </button>
  </Form>
)

const ApplyForHousingFormik = withFormik({
  mapPropsToValues({
    apply_for_housing_title,
    apply_for_housing_full_name,
    apply_for_housing_street_name,
    apply_for_housing_postcode,
    apply_for_housing_telephone,
    apply_for_housing_email,
    apply_for_housing_over_age,
    apply_for_housing_process_agreement,
  }) {
    return {
      apply_for_housing_title: "Mr",
      apply_for_housing_full_name: "",
      apply_for_housing_street_name: "",
      apply_for_housing_postcode: "",
      apply_for_housing_telephone: "",
      apply_for_housing_email: "",
      apply_for_housing_over_age: "Yes",
      apply_for_housing_process_agreement: false,
    }
  },
  validationSchema: yup.object().shape({
    apply_for_housing_title: yup.string(),
    apply_for_housing_full_name: yup
      .string()
      .min(3, "Full Name is too short")
      .max(100, "Full Name is too long")
      .required("Full Name is required"),
    apply_for_housing_street_name: yup
      .string()
      .min(3, "Street Name is too short")
      .max(100, "Street Name is too long")
      .required("Street Name is required"),
    apply_for_housing_postcode: yup
      .string()
      .min(5, "Postcode Name is too short")
      .max(10, "Postcode is too long")
      .required("Postcode is required"),
    apply_for_housing_telephone: yup
      .number("This field must be a number")
      .required("Telephone Number is required"),
    apply_for_housing_email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email Address is required"),
    apply_for_housing_process_agreement: yup
      .boolean()
      .oneOf([true], "Please tick to accept the agreement"),
  }),
  handleSubmit(values, { props }) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "apply_for_housing", ...values }),
    })
      .then(() => navigate("/thank-you"))
      .catch((error) => alert(error))
  },
})(ApplyForHousingForm)

export default ApplyForHousingFormik
