import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts"
import Seo from "../components/seo"
import Header from "../components/header"
import PageTitle from "../components/pageTitle"
import PageIntro from "../components/pageIntro"
import Features from "../components/features"
import LinkList from "../components/linkList"
import RentStatementFormik from "../components/forms/rentStatementForm"
import RepairsFormik from "../components/forms/repairsForm"
import ComplaintsComplimentsFormik from "../components/forms/complaintsComplimentsForm"
import ApplyForHousingFormik from "../components/forms/applyForHousingForm"
import imgGallery from "../utils/imgGallery"

class ChildTemplate extends Component {
  render() {
    const currentPage = this.props.data.allWpPage.edges[0].node,
      pageId =
        currentPage.parentId === 0 ? currentPage.id : currentPage.parentId,
      pageTitle = currentPage.parentId === 0 ? currentPage.title : false,
      introTitle =
        currentPage.introduction != null
          ? currentPage.introduction.introTitle
          : "",
      introDesc =
        currentPage.introduction != null ? currentPage.introduction.intro : "",
      form = currentPage.forms != null ? currentPage.forms.forms : "",
      //   seoTitle =
      //     currentPage.yoast_meta.yoast_wpseo_title !== null
      //       ? currentPage.yoast_meta.yoast_wpseo_title
      //       : currentPage.title,
      //   seoDesc = '',
      gallery =
        currentPage.imageGalleryContent != null
          ? currentPage.imageGalleryContent
          : null

    return (
      <Layout>
        <div className="page">
          <Seo seoTitle={currentPage.title} seoDesc={""} />
          <Header />
          <PageTitle pageId={pageId} pageTitle={pageTitle} />
          <PageIntro introTitle={introTitle} introDesc={introDesc} />

          <section className="section sectionWhite">
            <div className="containerFluid">
              <div className="row">
                <div className="col-bp1-12 col-bp4-4">
                  <h3 className="section__title">
                    <span>More in this section</span>
                  </h3>
                  <div className="linkListCompact">
                    <LinkList
                      parentId={currentPage.parentId}
                      pageSlug={currentPage.slug}
                    />
                  </div>
                </div>
                <div className="col-bp1-12 col-bp4-8 col-bp6-7">
                  <div
                    dangerouslySetInnerHTML={{ __html: currentPage.content }}
                    className="entry"
                  />

                  {imgGallery(gallery)}
                  {showForm(form)}
                </div>
              </div>
            </div>
          </section>

          <section className="section sectionWhite section--close-btm">
            <div className="features">
              <div className="containerFluid">
                <div className="row">
                  <div className="col-bp1-12">
                    <Features />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

function showForm(form) {
  // if forms isn't null
  // determine which form and then grab that form component
  if (form !== null) {
    if (form === "Rent Statement") {
      return (
        <div>
          <RentStatementFormik />
        </div>
      )
    } else if (form === "Repairs") {
      return (
        <div>
          <RepairsFormik />
        </div>
      )
    } else if (form === "Complaints and Compliments") {
      return (
        <div>
          <ComplaintsComplimentsFormik />
        </div>
      )
    } else if (form === "Apply For Housing") {
      return (
        <div>
          <ApplyForHousingFormik />
        </div>
      )
    }
  }
}

export const childPageQuery = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          databaseId
          title
          content
          parentId
          slug
          introduction {
            introTitle
            intro
          }
          forms {
            forms
          }
          imageGalleryContent {
            imageGallery {
              localFile {
                name
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
    allWpMenu(filter: { slug: { eq: "primary-menu" } }) {
      edges {
        node {
          name
          menuItems {
            nodes {
              databaseId
              order
              parentId
              title
              url
              path
            }
          }
        }
      }
    }
  }
`

export default ChildTemplate
