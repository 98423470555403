import React from "react"
import { navigate } from "gatsby-link"
import { withFormik, Form, Field } from "formik"
import * as yup from "yup"
import LinkCog from "../linkCog"

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const RentStatementForm = ({ values, errors, touched, isSubmitting }) => (
  <Form
    className="form"
    name="rent_statements"
    method="post"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
  >
    <input type="hidden" name="form-name" value="rent_statements" />
    <div className="form__group form__group--inline form__group--title">
      <label className="form__label" htmlFor="rent_statement_title">
        Title
      </label>
      <Field
        id="title"
        component="select"
        name="rent_statement_title"
        className="form__select form__select--title"
      >
        <option value="Mr">Mr</option>
        <option value="Mrs">Mrs</option>
        <option value="Miss">Miss</option>
        <option value="Ms">Ms</option>
        <option value="Dr">Dr</option>
      </Field>
      {errors.rent_statement_title && touched.rent_statement_title ? (
        <p className="form__error">{errors.rent_statement_title}</p>
      ) : null}
    </div>
    <div className="form__group form__group--inline form__group--full-name">
      <label className="form__label" htmlFor="rent_statement_full_name">
        Full Name
      </label>
      <Field
        type="text"
        className="form__text"
        name="rent_statement_full_name"
        id="rent_statement_full_name"
      />
      {errors.rent_statement_full_name && touched.rent_statement_full_name ? (
        <p className="form__error">{errors.rent_statement_full_name}</p>
      ) : null}
    </div>

    <div className="form__group form__group--inline form__group--address">
      <label className="form__label" htmlFor="rent_statement_street_name">
        Street Name
      </label>
      <Field
        type="text"
        className="form__text"
        id="rent_statement_street_name"
        name="rent_statement_street_name"
      />
      {errors.rent_statement_street_name &&
      touched.rent_statement_street_name ? (
        <p className="form__error">{errors.rent_statement_street_name}</p>
      ) : null}
    </div>
    <div className="form__group form__group--inline form__group--postcode">
      <label className="form__label" htmlFor="rent_statement_postcode">
        Postcode
      </label>
      <Field
        type="text"
        className="form__text"
        id="rent_statement_postcode"
        name="rent_statement_postcode"
      />
      {errors.rent_statement_postcode && touched.rent_statement_postcode ? (
        <p className="form__error">{errors.rent_statement_postcode}</p>
      ) : null}
    </div>
    <div className="form__group">
      <Field
        className="form__checkbox"
        id="rent_statement_tenant"
        name="rent_statement_tenant"
        type="checkbox"
      />
      <label
        className="form__label form__label--checkbox"
        htmlFor="rent_statement_tenant"
      >
        I am a tenant of this property
      </label>
      {errors.rent_statement_tenant && touched.rent_statement_tenant ? (
        <p className="form__error">{errors.rent_statement_tenant}</p>
      ) : null}
    </div>
    <div className="form__group form__group--inline form__group--telephone">
      <label className="form__label" htmlFor="rent_statement_telephone">
        Daytime Telephone Number
      </label>
      <Field
        type="text"
        className="form__text"
        id="rent_statement_telephone"
        name="rent_statement_telephone"
      />
      {errors.rent_statement_telephone && touched.rent_statement_telephone ? (
        <p className="form__error">{errors.rent_statement_telephone}</p>
      ) : null}
    </div>
    <div className="form__group form__group--inline form__group--email">
      <label className="form__label" htmlFor="rent_statement_email">
        Email Address
      </label>
      <Field
        type="email"
        className="form__text"
        id="rent_statement_email"
        name="rent_statement_email"
      />
      {errors.rent_statement_email && touched.rent_statement_email ? (
        <p className="form__error">{errors.rent_statement_email}</p>
      ) : null}
    </div>
    <div className="form__group">
      <label className="form__label" htmlFor="rent_statement_process_agreement">
        Data Protection
      </label>
      <p>
        PRHA promises to keep your data safe and secure and in line with the
        General Data Protection Regulation 2018. We will only use your
        information to provide the service you have requested and we will not
        sell your data. You can manage and review your privacy choices at any
        time by contacting You can view <b>PRHA's privacy notice here.</b>
      </p>
      <Field
        className="form__checkbox"
        id="rent_statement_process_agreement"
        name="rent_statement_process_agreement"
        type="checkbox"
        checked={values.rent_statement_process_agreement}
      />
      <label
        className="form__label form__label--checkbox"
        htmlFor="rent_statement_process_agreement"
      >
        Do you agree for PRHA to process your information?
      </label>
      {errors.rent_statement_process_agreement &&
      touched.rent_statement_process_agreement ? (
        <p className="form__error">{errors.rent_statement_process_agreement}</p>
      ) : null}
    </div>
    <button
      className="button button--gold"
      type="submit"
      disabled={isSubmitting}
    >
      Send this form <LinkCog />
    </button>
  </Form>
)

const RentStatementFormik = withFormik({
  mapPropsToValues({
    rent_statement_title,
    rent_statement_full_name,
    rent_statement_street_name,
    rent_statement_postcode,
    rent_statement_tenant,
    rent_statement_telephone,
    rent_statement_email,
    rent_statement_process_agreement,
  }) {
    return {
      rent_statement_title: "Mr",
      rent_statement_full_name: "",
      rent_statement_street_name: "",
      rent_statement_postcode: "",
      rent_statement_tenant: "",
      rent_statement_telephone: "",
      rent_statement_email: "",
      rent_statement_process_agreement: false,
    }
  },
  validationSchema: yup.object().shape({
    rent_statement_title: yup.string(),
    rent_statement_full_name: yup
      .string()
      .min(3, "Full Name is too short")
      .max(100, "Full Name is too long")
      .required("Full Name is required"),
    rent_statement_street_name: yup
      .string()
      .min(3, "Street Name is too short")
      .max(100, "Street Name is too long")
      .required("Street Name is required"),
    rent_statement_postcode: yup
      .string()
      .min(5, "Postcode Name is too short")
      .max(10, "Postcode is too long")
      .required("Postcode is required"),
    rent_statement_telephone: yup
      .number("This field must be a number")
      .required("Telephone Number is required"),
    rent_statement_email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email Address is required"),
    rent_statement_process_agreement: yup
      .boolean()
      .oneOf([true], "Please tick to accept the agreement"),
  }),
  handleSubmit(values, { props }) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "rent_statements", ...values }),
    })
      .then(() => navigate("/thank-you"))
      .catch((error) => alert(error))
  },
})(RentStatementForm)

export default RentStatementFormik
