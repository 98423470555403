import React from "react"
import { navigate } from "gatsby-link"
import { withFormik, Form, Field } from "formik"
import * as yup from "yup"
import LinkCog from "../linkCog"

// File constants
const MAX_FILE_SIZE = 10000000
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"]

const RepairsForm = ({ values, errors, touched, isSubmitting }) => (
  <Form
    className="form"
    name="repairs"
    action="/thank-you"
    method="post"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
  >
    <input type="hidden" name="form-name" value="repairs" />
    <div className="form__group form__group--inline form__group--title">
      <label className="form__label" htmlFor="repairs_title">
        Title
      </label>
      <Field
        id="title"
        component="select"
        name="repairs_title"
        className="form__select form__select--title"
      >
        <option value="Mr">Mr</option>
        <option value="Mrs">Mrs</option>
        <option value="Miss">Miss</option>
        <option value="Ms">Ms</option>
        <option value="Dr">Dr</option>
      </Field>
      {errors.repairs_title && touched.repairs_title ? (
        <p className="form__error">{errors.repairs_title}</p>
      ) : null}
    </div>
    <div className="form__group form__group--inline form__group--full-name">
      <label className="form__label" htmlFor="repairs_full_name">
        Full Name
      </label>
      <Field
        type="text"
        className="form__text"
        name="repairs_full_name"
        id="repairs_full_name"
      />
      {errors.repairs_full_name && touched.repairs_full_name ? (
        <p className="form__error">{errors.repairs_full_name}</p>
      ) : null}
    </div>

    <div className="form__group form__group--inline form__group--address">
      <label className="form__label" htmlFor="repairs_street_name">
        Street Name
      </label>
      <Field
        type="text"
        className="form__text"
        id="repairs_street_name"
        name="repairs_street_name"
      />
      {errors.repairs_street_name && touched.repairs_street_name ? (
        <p className="form__error">{errors.repairs_street_name}</p>
      ) : null}
    </div>
    <div className="form__group form__group--inline form__group--postcode">
      <label className="form__label" htmlFor="repairs_postcode">
        Postcode
      </label>
      <Field
        type="text"
        className="form__text"
        id="repairs_postcode"
        name="repairs_postcode"
      />
      {errors.repairs_postcode && touched.repairs_postcode ? (
        <p className="form__error">{errors.repairs_postcode}</p>
      ) : null}
    </div>
    <div className="form__group form__group--inline form__group--telephone">
      <label className="form__label" htmlFor="repairs_telephone">
        Daytime Telephone Number
      </label>
      <Field
        type="text"
        className="form__text"
        id="repairs_telephone"
        name="repairs_telephone"
      />
      {errors.repairs_telephone && touched.repairs_telephone ? (
        <p className="form__error">{errors.repairs_telephone}</p>
      ) : null}
    </div>
    <div className="form__group form__group--inline form__group--email">
      <label className="form__label" htmlFor="repairs_email">
        Email Address
      </label>
      <Field
        type="email"
        className="form__text"
        id="repairs_email"
        name="repairs_email"
      />
      {errors.repairs_email && touched.repairs_email ? (
        <p className="form__error">{errors.repairs_email}</p>
      ) : null}
    </div>
    <div className="form__group">
      <label
        className="form__label"
        htmlFor="repairs_first_preferred_contact_method"
      >
        Preferred Contact Method
      </label>
      <p>
        How would you like PRHA to contact you regarding the status of this
        repair?
      </p>
      <Field component="div" name="repairs_first_preferred_contact_method">
        <div className="form__radio">
          <input
            type="radio"
            id="telephone"
            name="repairs_first_preferred_contact_method"
            value="telephone"
          />
          <label htmlFor="telephone">Telephone</label>
        </div>
        <div className="form__radio">
          <input
            type="radio"
            id="email"
            name="repairs_first_preferred_contact_method"
            value="email"
          />
          <label htmlFor="email">Email</label>
        </div>
        <div className="form__radio">
          <input
            type="radio"
            id="letter"
            name="repairs_first_preferred_contact_method"
            value="letter"
          />
          <label htmlFor="letter">Letter</label>
        </div>
      </Field>
      {errors.repairs_first_preferred_contact_method &&
      touched.repairs_first_preferred_contact_method ? (
        <p className="form__error">
          {errors.repairs_first_preferred_contact_method}
        </p>
      ) : null}
    </div>
    <div className="form__group">
      <label className="form__label" htmlFor="repairs_repair_location">
        Repair location
      </label>
      <p>Where does the repair need carried out?</p>
      <Field
        id="title"
        component="select"
        name="repairs_repair_location"
        className="form__select"
      >
        <option value="None" disabled>
          Please select
        </option>
        <option value="Bedroom">Bedroom</option>
        <option value="Bathroom">Bathroom</option>
        <option value="Living Room">Living room</option>
        <option value="Kitchen">Kitchen</option>
        <option value="Hallway">Hallway</option>
        <option value="Garden">Garden</option>
        <option value="Communal_area">Communal Area</option>
        <option value="Other">Other</option>
      </Field>
      {errors.repairs_repair_location && touched.repairs_repair_location ? (
        <p className="form__error">{errors.repairs_title}</p>
      ) : null}
    </div>
    <div className="form__group">
      <label className="form__label" htmlFor="repairs_repair_nature">
        Nature of repair
      </label>
      <p>Help us understand the type of repair that is required</p>
      <Field
        id="title"
        component="select"
        name="repairs_repair_nature"
        className="form__select"
      >
        <option value="None" disabled>
          Please select
        </option>
        <option value="General">General repair/maintenance</option>
        <option value="Gas Safety">Gas Safety</option>
        <option value="Water Pipes">Water/pipes</option>
        <option value="Fire Safety">Fire safety</option>
        <option value="Other">Other</option>
      </Field>
      {errors.repairs_repair_nature && touched.repairs_repair_nature ? (
        <p className="form__error">{errors.repairs_repair_nature}</p>
      ) : null}
    </div>
    <div className="form__group">
      <label className="form__label" htmlFor="repairs_details">
        Repair request details
      </label>
      <p>
        Please include as much detail as you can to enable us to carry out your
        repair as quickly as possible.
      </p>
      <Field
        component="textarea"
        className="form__textarea"
        id="repairs_details"
        name="repairs_details"
      />
      <p className="form__character-count">0 of 1000 characters</p>
      {errors.repairs_details && touched.repairs_details ? (
        <p className="form__error">{errors.repairs_details}</p>
      ) : null}
    </div>
    <div className="form__group">
      <label className="form__label" htmlFor="repairs_preferred_time">
        Preferred appointment time
      </label>
      <p>
        Please let us know when you are likely to be available to give us access
        to carry out the repair in your property
      </p>
      <Field component="div" name="repairs_preferred_time">
        <div className="form__radio">
          <input
            type="radio"
            id="morning"
            name="repairs_preferred_time"
            value="morning"
          />
          <label htmlFor="morning">
            Morning appointment (8:30am - 12:00pm)
          </label>
        </div>
        <div className="form__radio">
          <input
            type="radio"
            id="afternoon"
            name="repairs_preferred_time"
            value="afternoon"
          />
          <label htmlFor="afternoon">
            Afternoon appointment (1:00pm - 4:00pm)
          </label>
        </div>
        {errors.repairs_preferred_time && touched.repairs_preferred_time ? (
          <p className="form__error">{errors.repairs_preferred_time}</p>
        ) : null}
      </Field>
    </div>
    <div className="form__group">
      <label className="form__label" htmlFor="repairs_file">
        Upload picture
      </label>
      <p>Maximum file size is 10MB</p>
      <Field name="repairs_file" component={FileUpload} />
      {errors.repairs_file && touched.repairs_file ? (
        <p className="form__error">{errors.repairs_file}</p>
      ) : null}
    </div>
    <div className="form__group">
      <label className="form__label" htmlFor="repairs_process_agreement">
        Data Protection
      </label>
      <p>
        PRHA will provide contractors with your contact telephone number so that
        the contractor can make an appointment with you. Please tick this box to
        provide your consent to PRHA to pass on your contact details to the
        relevant contractor.
      </p>
      <Field
        className="form__checkbox"
        id="repairs_process_agreement"
        name="repairs_process_agreement"
        type="checkbox"
        checked={values.repairs_process_agreement}
      />
      <label
        className="form__label form__label--checkbox"
        htmlFor="repairs_process_agreement"
      >
        Do you agree for PRHA to process your information?
      </label>
      {errors.repairs_process_agreement && touched.repairs_process_agreement ? (
        <p className="form__error">{errors.repairs_process_agreement}</p>
      ) : null}
    </div>
    <button
      className="button button--gold"
      type="submit"
      disabled={isSubmitting}
    >
      Submit request <LinkCog />
    </button>
  </Form>
)

function FileUpload(props) {
  const { form } = props

  // Let's supply each file data to Formik
  // yup will validate them in validation schema
  const handleChange = (e) => {
    const files = e.currentTarget.files
    const filesArr = Object.entries(files)

    // Supply info back to the File Reader
    filesArr.map((file) => {
      const reader = new FileReader()
      file = file[1]
      return reader.readAsDataURL(file)
    })

    // Set the field values so they are passed as props to Formik
    form.setFieldValue("repairs_file", files)
  }

  return (
    <input type="file" name="repairs_file" onChange={(o) => handleChange(o)} />
  )
}

// Formik will handle the form
// We'll use yup to validate fields
const RepairsFormik = withFormik({
  mapPropsToValues({
    repairs_title,
    repairs_full_name,
    repairs_street_name,
    repairs_postcode,
    repairs_telephone,
    repairs_email,
    repairs_first_preferred_contact_method,
    repairs_repair_location,
    repairs_repair_nature,
    repairs_details,
    repairs_preferred_time,
    repairs_file,
    repairs_process_agreement,
  }) {
    return {
      repairs_title: "Mr",
      repairs_full_name: "",
      repairs_street_name: "",
      repairs_postcode: "",
      repairs_telephone: "",
      repairs_email: "",
      repairs_first_preferred_contact_method: "telephone",
      repairs_repair_location: "Bedroom",
      repairs_repair_nature: "General",
      repairs_details: "",
      repairs_preferred_time: "morning",
      repairs_file: [],
      repairs_process_agreement: false,
    }
  },
  validationSchema: yup.object().shape({
    repairs_title: yup.string(),
    repairs_full_name: yup
      .string()
      .min(3, "Full Name is too short")
      .max(100, "Full Name is too long")
      .required("Full Name is required"),
    repairs_street_name: yup
      .string()
      .min(3, "Street Name is too short")
      .max(100, "Street Name is too long")
      .required("Street Name is required"),
    repairs_postcode: yup
      .string()
      .min(5, "Postcode Name is too short")
      .max(10, "Postcode is too long")
      .required("Postcode is required"),
    repairs_telephone: yup
      .number("This field must be a number")
      .required("Telephone Number is required"),
    repairs_email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email Address is required"),
    repairs_repair_location: yup
      .string()
      .required("Please provide the repair location"),
    repairs_repair_nature: yup
      .string()
      .required("Please provide the nature of the repair"),
    repairs_file: yup
      .mixed()
      .test(
        "fileSize",
        "Files must be under 10MB",
        (value) => value && testFileSizes(value)
      )
      .test(
        "fileFormat",
        "This form only accepts these file formats: jpeg, jpg, png, gif",
        (value) => value && testFileTypes(value)
      ),
    repairs_details: yup
      .string()
      .max(1000, "Maximum characters exceeded")
      .required("Please provide details of the repairs"),
    repairs_process_agreement: yup
      .boolean()
      .oneOf([true], "Please tick to accept the agreement"),
  }),
  handleSubmit(values, { props }) {
    // Now let's send the form to Netlify
    // Via fetch
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": "repairs",
        ...values,
      }),
    })
      .then(() => navigate("/thank-you"))
      .catch((error) => alert(error))
  },
})(RepairsForm)

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    if (key === "repairs_file") {
      const files = Object.entries(data[key])
      let filesArr = []
      files.map((file) => {
        return filesArr.push(file[1])
      })
      formData.append(key, filesArr)
    } else {
      formData.append(key, data[key])
    }
  }
  return formData
}

function testFileSizes(value) {
  let arrays = Object.entries(value) // make array from object
  // loop until we have a file larger than MAX_FILE_SIZE
  for (let i = 0; i < arrays.length; i++) {
    let arr = arrays[i]
    if (arr[1].size > MAX_FILE_SIZE) return false
  }
  return true
}

function testFileTypes(value) {
  let arrays = Object.entries(value) // make array from object
  // loop until we have an invalid file type
  for (let i = 0; i < arrays.length; i++) {
    let arr = arrays[i]
    if (SUPPORTED_FORMATS.includes(arr[1].type) === false) return false
  }
  return true
}

export default RepairsFormik
